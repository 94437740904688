import {createStore} from 'vuex';
import {auth} from './auth.module';
import {icons} from './icons.module';

const store = createStore({
	modules: {
		auth,
		icons,
	},
});

export default store;
