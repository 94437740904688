import AuthService from '../services/auth.service';
import RoleService from '../services/role.service';

const user = JSON.parse(localStorage.getItem('user'));
const roles = JSON.parse(localStorage.getItem('roles'));

// const initialStates = user ? {status: {loggedIn: true}, user: user} : {status: {loggedIn: false}, user: null};

const initialState = {
	status: {
		loggedIn: user ? true : false,
	},
	user: user ? user : null,
	roles: roles ? roles : null,
};

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({commit}, user) {
			return AuthService.login(user).then(
				(user) => {
					commit('loginSuccess', user);
					return Promise.resolve(user);
				},
				(error) => {
					commit('loginFailure');
					return Promise.reject(error);
				}
			);
		},
		fetchRoles({commit}) {
			return RoleService.getAll().then(
				(response) => {
					commit('fetchRolesSuccess', response);
					return Promise.resolve(roles);
				},
				(error) => {
					commit('fetchRolesFailure');
					return Promise.reject(error);
				}
			);
		},
		logout({commit}) {
			AuthService.logout();
			commit('logout');
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		fetchRolesSuccess(state, response) {
			if (response.data.roles) {
				localStorage.setItem('roles', JSON.stringify(response.data.roles));
				state.roles = response.data.roles;
			}
		},
		fetchRolesFailure(state) {
			state.roles = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			localStorage.removeItem('roles');
			state.roles = null;
			state.user = null;
		},
	},
};
