const initialState = {
	options: {
		0: 'Default',
		1: 'Eternity',
		2: 'Half',
		3: 'Bilabiale',
		4: 'Dot',
		5: 'A',
	},
	paths : {
		0: '߷',
		1: '֎',
		2: '½',
		3: 'ʘ',
		4: '•',
		5: 'A',
	}
};

export const icons = {
	namespaced: true,
	state: initialState,
	actions: {},
	mutations: {},
};
