import axios from 'axios';
import authHeader from './auth.header';

const API_URL = process.env.VUE_APP_API_URL;

class RoleService {
	getAll() {
		return axios.get(API_URL + 'roles', {headers: authHeader()});
	}
}

export default new RoleService();
