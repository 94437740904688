<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Imprint</h1>
				<p>Static</p>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>
</template>

<script>
export default {
	name: 'AppImprint',
	data() {
		return {
			data: ''
		};
	},
	mounted() {
	},
};
</script>
