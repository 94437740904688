<template>
	<div id="app">
		<nav v-if="currentUser" class="navbar navbar-expand navbar-dark bg-secondary mb-5">
			<a href="/" class="navbar-brand ms-3">API</a>

			<div class="navbar-nav w-100">
				<li class="nav-item d-flex align-items-center ms-5">
					<router-link v-if="currentUser" to="/items" class="nav-link">Sprüche</router-link>
				</li>
				<li class="nav-item d-flex align-items-center">
					<router-link to="/help" class="nav-link"> Hilfe </router-link>
				</li>
				<li v-if="showUsersPage" class="nav-item d-flex align-items-center">
					<router-link to="/users" class="nav-link">Users</router-link>
				</li>
				<!--
				<li class="nav-item d-flex align-items-center">
					<router-link v-if="currentUser" to="/files" class="nav-link">Files</router-link>
				</li>
				-->
				<li class="nav-item d-flex align-items-center ms-auto">
					<router-link to="/profile" class="nav-link d-flex align-items-center">
						<div class="bg-dark rounded-circle avatar d-flex align-items-center justify-content-center fs-4 me-2"> {{ avatar }} </div>
						Profile
					</router-link>
				</li>
				<li class="nav-item me-5 d-flex align-items-center">
					<a class="nav-link" href="javascript:void(0);" @click.prevent="logOut"> Ausloggen </a>
				</li>
			</div>
		</nav>

		<router-view />

		<footer class="footer bg-dark position-fixed bottom-0 start-0 w-100 pt-2 pb-2">
			<div class="container">
				<div class="row">
					<div class="col">
						<ul class="nav">
							<li class="nav-item">
								<router-link to="/help" class="me-3"> Home </router-link>
							</li>
							<li class="nav-item">
								<router-link to="/imprint" class="me-3"> Imprint </router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
export default {
	computed: {
		avatar() {
			return this.$store.state.icons.paths[this.$store.state.auth.user.icon];
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
		showUsersPage() {
			if (this.currentUser && this.currentUser['role_name']) {
				return this.currentUser['role_name'] === 'admin';
			}

			return false;
		},
	},
	methods: {
		logOut() {
			this.$store.dispatch('auth/logout');
			this.$router.push('/login');
		},
	},
};
</script>

<style lang="scss">
$primary: #f60;
$border-radius: 0;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-pill: 50rem;

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
// @import "../node_modules/bootstrap/scss/card";
// @import "../node_modules/bootstrap/scss/accordion";
// @import "../node_modules/bootstrap/scss/breadcrumb";
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
// @import "../node_modules/bootstrap/scss/progress";
@import '../node_modules/bootstrap/scss/list-group';
// @import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/toasts";
@import '../node_modules/bootstrap/scss/modal';
// @import "../node_modules/bootstrap/scss/tooltip";
// @import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
@import '../node_modules/bootstrap/scss/spinners';
// @import "../node_modules/bootstrap/scss/offcanvas";
// @import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';

.error-feedback {
	margin-top: 0.5rem;
}
.avatar {
	width: 2rem;
	height: 2rem;
	color: #FFF;
	line-height: 1;
}
.footer {
	z-index: 100;
}
</style>
